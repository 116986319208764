import React, { useState,useEffect } from 'react';
import './EditableCard.css';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import axios from 'axios';
import api from '../Services/api';
import {COLORS} from './colors';

const MasterdataTableAdmin = () => {
    const [vendorData, setVendorData] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const[assessorOptions,setAssessorOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]); 
    const[clientFormData,setClientFormData]=useState({
        
        clientName: '',
        companyId: '',
        principleEmployer: '',
        companyAddress: '',
        placeName: '',
        clientContactPersonName: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber: '',
        stateId: ''
    });

   const  [assessorFormData,setAssessorFormData] = useState({
    assessorCompanyName:'',
    assessorId: '',
   
   });
   const  [vendorFormData,setVendorFormData] = useState({
    vendorCompanyName:'',
    vendorId: '',
   
   });


    useEffect(() => {
        axios.get('http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames')
          .then(response => {
            setClientOptions(response.data);
          })
          .catch(error => {
            console.error('Error fetching company names:', error);
          });
      }, []);
    

    
      const handleChange = (event) => {
        const { name, value } = event.target;

        // Handle changes for clientName dropdown
        if (name === 'clientName') {
            axios.get('http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames')
                .then(response => {
                    const selectedClient = response.data.find(client => client.clientName === value);
                    console.log("Selected client:", selectedClient);
                    if (selectedClient) {
                        const { companyId } = selectedClient; 
                        setClientFormData(prevState => ({
                            ...prevState,
                            clientName: value,
                            companyId, 
                        }));

                       
                        api.admin.getassessor(companyId) 
                            .then(response => {
                                setAssessorOptions(response.data.data);
                                console.log("assessorData",response.data.data) 
                            })
                            .catch(error => {
                                console.error('Error fetching assessor details:', error);
                            });
                    }
                })
                .catch(error => {
                    console.error('Error fetching vendor details:', error);
                });
        }

      
        if (name === 'assessorName') {
            const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
            if (selectedAssessor) {
                setAssessorFormData(prevState => ({
                    ...prevState,
                    assessorCompanyName: value,
                    assessorId: selectedAssessor.assessorId, 
                }));

                fetchVendor(selectedAssessor.assessorId);
            }
        }
        
        if (name === 'vendorName') {
            const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
            if (selectedVendor) {
                setVendorFormData(prevState => ({
                    ...prevState,
                    vendorCompanyName: value,
                    vendorId: selectedVendor.vendorId, 
                }));

                fetchVendorData(selectedVendor.vendorId);
            }
        }
        
      
        if (name !== 'clientName' && name !== 'assessorName') {
            setClientFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


const fetchVendor = async (assessorId) => {
     console.log("id",assessorId);
    try {
        const response = await api.assessor.getVendors(assessorId); 
        const vendorDetails = response.data.data;
        setVendorOptions(vendorDetails); 
       
    } catch (error) {
        console.error("Vendor could not be fetched:", error);
    }
};
 
  const fetchVendorData = async (vendorId) => {
     
    try {
      const response = await axios.get(`http://35.154.135.215:8080/api/companyMasterData/vendorDetails/${vendorId}`);
      setVendorData(response.data.data);
      const newCompletedCheckboxes = [];
      const newRejectedCheckboxes = [];
      const newRemarks = [];
 
      // Iterate over the fetched data and set the values accordingly
      for (let i = 0; i < titles.length; i++) {
       
        const rowData = response.data.data[Object.keys(response.data.data)[i]];
       
        if (rowData) {
          if (rowData.validation === 2) {
            newCompletedCheckboxes[i] = true;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = rowData.remark;
           
           
          } else if (rowData.validation === 3) {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = true;
            newRemarks[i] = rowData.remark;
           
           
          }else if(rowData.validation === 1) {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = " ";
            
          }
          else {
            newCompletedCheckboxes[i] = false;
            newRejectedCheckboxes[i] = false;
            newRemarks[i] = " ";
          }
          
        } 
      }
     
      setCompletedCheckboxes(newCompletedCheckboxes);
      setRejectedCheckboxes(newRejectedCheckboxes);
      setRemarks(newRemarks);  
     
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
 
 
 
    const titles = [
        "Date of Commencement",
        "Date of Completion",
        "PO Copy Number",
        "EPFO Allotment copy",
        "ESIC allotment copy",
        "P Tax RC Number",
        "PT EC Number",
        "LWF Number",
        "Factory Licence",
        "Building plan layout",
        "Stability Certificate",
        "Concern to Establishment",
        "Concern to Operations",
        "Fire Advisory",
        "Fire NOC",
        "Environment Clearance",
        "Medical/Hospital - Certificate",
        "Apprenticeship Registration",
        "NEEM/NAPS - Registration",
        "CLRA RC",
        "S&CE RC",
        "CLRA Licence",
        "BOCW RC",
        "ISMW RC",
        "ISMW Licence",
        "PASSARA Licence",
        "FSSAI Licence"
    ];
    const [completedCheckboxes, setCompletedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [rejectedCheckboxes, setRejectedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [remarks, setRemarks] = useState(new Array(titles.length).fill(''));
    const [savedStatus, setSavedStatus] = useState(new Array(titles.length).fill(false));
    

    const handleCheckboxChange = (index, column) => {
      setSavedStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[index] = false;
        return newStatus;
    });
        if (column === 'accepted') {
            const newCompletedCheckboxes = [...completedCheckboxes];
            newCompletedCheckboxes[index] = !newCompletedCheckboxes[index];
            // If completed checkbox is clicked, disable the rejected checkbox
            if (newCompletedCheckboxes[index]) {
                const newRejectedCheckboxes = [...rejectedCheckboxes];
                newRejectedCheckboxes[index] = false;
                setRejectedCheckboxes(newRejectedCheckboxes);
            }
            setCompletedCheckboxes(newCompletedCheckboxes);
        } else if (column === 'rejected') {
            const newRejectedCheckboxes = [...rejectedCheckboxes];
            newRejectedCheckboxes[index] = !newRejectedCheckboxes[index];
            // If rejected checkbox is clicked, disable the completed checkbox
            if (newRejectedCheckboxes[index]) {
                const newCompletedCheckboxes = [...completedCheckboxes];
                newCompletedCheckboxes[index] = false;
                setCompletedCheckboxes(newCompletedCheckboxes);
            }
            setRejectedCheckboxes(newRejectedCheckboxes);
        }
    };
    const handleRemarkChange = (index, value) => {
        const newRemarks = [...remarks];
        newRemarks[index] = value;
        setRemarks(newRemarks);
        setSavedStatus(prevStatus => {
          const newStatus = [...prevStatus];
          newStatus[index] = false;
          return newStatus;
      });
 
    };
   
 const handleViewDocument = (fileUrl) => {
window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')
  };
 
    const handleSave = (index,title) => {
      const newSavedStatus = [...savedStatus];
      newSavedStatus[index] = true;
      setSavedStatus(newSavedStatus);
      const selectedVendorId = localStorage.getItem('selectedVendorId');
     
     
 
        const masterdata = {
            vendorId: selectedVendorId,  
        };
        if (index === 0) {
            masterdata.dateOfCommencement = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
           
          }
        if (index === 1) {
            masterdata.dateOfCompletion = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
           
          }
       
          if (index === 2) {
            masterdata.poCopy = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 3) {
            masterdata.epfoAllotment = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 4) {
            masterdata.esicAllotment = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 5) {
            masterdata.pTaxRc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 6) {
            masterdata.ptEc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 7) {
            masterdata.lwf = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 8) {
            masterdata.factoryLicence = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 9) {
            masterdata.buildingPlanLayout = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 10) {
            masterdata.stabilityCertificate = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 11) {
            masterdata.concernToEstablishment = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 12) {
            masterdata.concernToOperations = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 13) {
            masterdata.fireAdvisory = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 14) {
            masterdata.fireNoc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 15) {
            masterdata.environmentClearance = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 16) {
            masterdata.medicalOrHospitalCertificate = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 17) {
            masterdata.apprenticeshipRegistration = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 18) {
            masterdata.neemOrNapsRegistration = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 19) {
            masterdata.clraRc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 20) {
            masterdata.sAndCeRc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 21) {
            masterdata.clraLicence = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 22) {
            masterdata.bocwRc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 23) {
            masterdata.ismwRc = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
          if (index === 24) {
            masterdata.ismwLicence = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
        if (index === 25) {
            masterdata.passaraLicence = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
       
        if (index === 26) {
            masterdata.fssaiLicence = {
                value: vendorData[Object.keys(vendorData)[index]].value,
       
                fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
             
                    validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
              remark: remarks[index],
              na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
            };
          }
     
 
        axios.put('http://35.154.135.215:8080/api/companyMasterData/updateMasterData', masterdata)
            .then(response => {
                console.log("Masterdata updated successfully:", response);
            })
            .catch(error => {
                console.error("Error updating masterdata:", error);
            });
    };
 
    const rows = titles.map((title, index) => {
      let rowClassName = '';
      if (vendorData && vendorData[Object.keys(vendorData)[index]]) {
          const validationStatus = vendorData[Object.keys(vendorData)[index]].validation;
          if (validationStatus === 1) {
              rowClassName = 'pending';
          } else if (validationStatus === 2) {
              rowClassName = 'accepted';
          } else if (validationStatus === 3) {
              rowClassName = 'rejected';
          }
      }
      if (completedCheckboxes[index]) {
          rowClassName = 'accepted';
      } else if (rejectedCheckboxes[index]) {
          rowClassName = 'rejected';
      }
      let filename = '';
      if (
        vendorData &&
        vendorData[Object.keys(vendorData)[index]] &&
        vendorData[Object.keys(vendorData)[index]].fileUrl
      ) {
        const fullUrl = vendorData[Object.keys(vendorData)[index]].fileUrl;
        filename = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
      }
  return (
    <tr className={rowClassName} >
                <td>{title}</td>
                <td>
                {vendorData && (
                    <div style={{overflowX:'auto',overflowY:'hidden',width:'200px',height:'50px'}}>
                       
                        <p >{vendorData[Object.keys(vendorData)[index]].value}</p>
                    </div>
                )}
            </td>
                <td>   {vendorData && (
                    <>
                       
                       {filename && (
    <div >
     <button className='btn btn-sm btn-primary' onClick={() => handleViewDocument(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-eye"></i> Doc</button>
     <button className="btn mx-1 btn-sm  btn-primary" onClick={()=>window.open(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-download"></i> Doc</button>
    </div>
   
  )}
                    </>
                )}</td>
                <td>   {vendorData && (
                    <>
                       
                        <p>{vendorData[Object.keys(vendorData)[index]].na===false?"Applicable":"Not Applicable"}</p>
                    </>
                )}</td>
               
                <td>
                    <input
                        type="checkbox"
                        checked={completedCheckboxes[index]}
                        onChange={() => handleCheckboxChange(index, 'accepted')}
                    />
                </td>
                <td>
                    <input
                        type="checkbox"
                        checked={rejectedCheckboxes[index]}
                        onChange={() => handleCheckboxChange(index, 'rejected')}
                    />
                </td>
                <td className="remark">
                <textarea
                    required={rejectedCheckboxes[index]}
                    // disabled={completedCheckboxes[index]}
                    value={remarks[index]}
                    onChange={e => handleRemarkChange(index, e.target.value)}
                ></textarea>
            </td>
 
              <td>
                <button type="submit" onClick={()=>handleSave(index,title)}  disabled={(!completedCheckboxes[index] && !rejectedCheckboxes[index]) || (!completedCheckboxes[index] && rejectedCheckboxes[index] && !remarks[index])} className="btn btn-dark"> {savedStatus[index] ? "Saved" : "Save"}</button>
              </td>
            </tr>
        );
    });
   
   
 
   
   
    return (
        <div>
         
         
            <section style={{paddingTop:'20px',paddingLeft:'10px',paddingBottom:'20px',display:'flex',justifyContent:'space-between '}}>
                <div>
            {/* <h6>Client Name:XYZ</h6> */}
            <Select
 
 value={clientFormData.clientName} // Ensure default is an empty string
 onChange={handleChange}
 name="clientName"
 displayEmpty
 
>
 <MenuItem value="" disabled>Select Client</MenuItem> {/* Use an empty string */}
 {Array.isArray(clientOptions) && clientOptions.map((client, index) => (
   <MenuItem key={index} value={client.clientName}>
     {client.clientName} {client.stateId}
   </MenuItem>
   
 ))}

 
</Select>
<Select
                value={assessorFormData.assessorCompanyName}
                onChange={handleChange}
                name="assessorName"
                displayEmpty
            >
                <MenuItem value="" disabled>Select Assessor</MenuItem>
                {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
                    <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                        {assessor.assessorCompanyName} {assessor.assessorAddress}
                    </MenuItem>
                ))}
            </Select>
            <Select
                value={vendorFormData.vendorCompanyName}
                onChange={handleChange}
                name="vendorName"
                displayEmpty
            >
                <MenuItem value="" disabled>Select Vendor</MenuItem>
                {Array.isArray(vendorOptions) && vendorOptions.map((vendor) => (
                    <MenuItem key={vendor.vendorId} value={vendor.vendorCompanyName}>
                        {vendor.vendorCompanyName} {vendor.vendorCompanyAddress}
                    </MenuItem>
                ))}
            </Select>
           

            </div>
            <div style={{ letterSpacing: '1px' }}>
        <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', marginLeft:'8px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Under review</span>
          <div className="vr  mx-3"></div>
          <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Accepted</span>
          <div className="vr mx-3"></div>
          <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Rejected</span>
        </div>
            </section>
         
            <table className="fixed-header-table">
                <thead>
                    <tr>
                        <th>Name of DOC</th>
                        <th>Date/Doc No</th>
                        <th>Uploaded File</th>
                        <th>Not Applicable/Applicable</th>
                        <th>Accepted</th>
                        <th>Rejected</th>
                        <th>Remark</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
 
        </div>
    );
};

export default MasterdataTableAdmin
