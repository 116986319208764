import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import api from '../Services/api';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Select, 
  MenuItem,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const VendorList = () => {
 
  const [vendors, setVendors] = useState([]);
  const [expandedVendor, setExpandedVendor] = useState(null);
  const [editingVendor, setEditingVendor] = useState(null);
  const [formData, setFormData] = useState({
    clientName: '',
    companyId: '',
    companyAddress: '',
    stateId: '',
    vendorId:'',
    placeName: '',
    principleEmployer:'',
    clientContactPersonName: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber: '',
    vendorCompanyName: '',
    vendorCompanyAddress: '',
    natureOfWork: '',
    vendorName:'',
    vendorPhoneNumber: '',
    vendorEmail: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
  });
  const [clientOptions, setClientOptions] = useState([]);

  const fetchData = async () => {
    let data = localStorage.getItem('user');
    let assessorData = JSON.parse(data);
    let id = assessorData.id;
    console.log('Assessor Id:', id);

    try {
      const response = await api.assessor.getVendors(id);
      console.log('vendor data:', response.data.data);
      setVendors(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    axios.get('http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames')
      .then(response => {
        setClientOptions(response.data);
        console.log("client data",response.data);
      })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  }, []);

  const handleExpandClick = (vendorId) => {
    setExpandedVendor(expandedVendor === vendorId ? null : vendorId);
  };

  const handleEditClick = (vendor) => {
    setEditingVendor(vendor.id);
   setFormData({ ...vendor });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
  
    // Handle changes for companyName dropdown
    if (name === 'clientName') {
      axios.get(`http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames`)
        .then(response => {
          const selectedClient = response.data.find(client => client.clientName === value);
          console.log("get companyname:", selectedClient );
          if (selectedClient) {
            const {  companyAddress ,companyId, placeName , clientContactPersonName,  clientContactPersonEmailId ,clientContactPersonPhoneNumber , principleEmployer,stateId} = selectedClient;
            setFormData(prevState => ({
              ...prevState,
             clientName: value,
              companyId,
            principleEmployer,
              companyAddress,
              placeName, 
              clientContactPersonName,
            clientContactPersonEmailId,
              clientContactPersonPhoneNumber,
              stateId,
        
            }));
            console.log("clientId",selectedClient.companyId)
          }
        })
        .catch(error => {
          console.error('Error fetching vendor details:', error);
        });
    }
  
    if (name !== 'clientName') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSave = async () => {
    try {
      console.log('formData',formData);
    const response =  await api.assessor.editVendor(formData.vendorId, formData);
      if(response.status===200){
      window.alert("Updated successfully!!!!");
      fetchData();
      }
      else{
        window.alert("error in submitting");
      }
      
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCancel = () => {
    setEditingVendor(null);
  };

  return (
    <div className="m-5">
   
      {vendors.length > 0 ? (
        vendors.map((vendor) => (
          <Accordion
            key={vendor.id}
            expanded={expandedVendor === vendor.id}
            onChange={() => handleExpandClick(vendor.id)}
           

          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{backgroundColor:'black',color:'white'}}/>}
              aria-controls={`panel-${vendor.id}-content`}
              id={`panel-${vendor.id}-header`}
              style={{border:'1px solid #F9F6EE'}}
            >
              <Typography variant="h6">
                {vendor.vendorCompanyName}
              </Typography>
              <Button onClick={() => handleEditClick(vendor)} variant="contained" style={{position:'absolute',right:'60px'}}>
                            Edit
                          </Button>
                          {/* <Button onClick={() => handleEditClick(vendor)} variant='contained' color="error" style={{position:'absolute',right:'50px'}}>
                            Delete
                          </Button> */}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                      {editingVendor === vendor.id ? (
                        <>
                        <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Company Name"
                            name="vendorCompanyName"
                            value={formData.vendorCompanyName}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Company Address"
                            name="vendorCompanyAddress"
                            value={formData.vendorCompanyAddress}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Vendor Name"
                            name="vendorName"
                            value={formData.vendorName}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            name="vendorEmail"
                            value={formData.vendorEmail}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Phone"
                            name="vendorPhoneNumber"
                            value={formData.vendorPhoneNumber}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Nature of Work"
                            name="natureOfWork"
                            value={formData.natureOfWork}
                            onChange={handleFormChange}
                            margin="normal"
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Contract Start Date"
                            name="vendorContractStartDate"
                            type="date"
                            value={new Date(formData.vendorContractStartDate).toISOString().split('T')[0]}
                            onChange={handleFormChange}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Contract End Date"
                            name="vendorContractEndDate"
                            type="date"
                            value={new Date(formData.vendorContractEndDate).toISOString().split('T')[0]}
                            onChange={handleFormChange}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                          />
                          </Grid>
                          <Grid item xs={12} md={6}>
                          
                          <Button onClick={handleCancel} variant="contained" color="error" >
                            Cancel
                          </Button>
                          <Button onClick={handleSave} variant='contained' color="success" style={{position:'absolute',right:'20px'}}>
                            Save
                          </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                        <Grid item xs={6}>
                          <Typography variant="body1">Company Name: {vendor.vendorCompanyName}</Typography>
                          <Typography variant="body1">Address: {vendor.vendorCompanyAddress}</Typography>
                          <Typography variant="body1">Nature Of Work: {vendor.natureOfWork}</Typography>
                          <Typography variant="body1">Vendor Name: {vendor.vendorName}</Typography>
                          <Typography variant="body1">Phone: {vendor.vendorPhoneNumber}</Typography>
                          <Typography variant="body1">Vendor Name: {vendor.vendorName}</Typography>
                          <Typography variant="body1">Email: {vendor.vendorEmail}</Typography>
                          <Typography variant="body1">Start Date: {new Date(vendor.vendorContractStartDate).toISOString().split('T')[0]}</Typography>
                          <Typography variant="body1">End Date: {new Date(vendor.vendorContractEndDate).toISOString().split('T')[0]}</Typography>
                       
                        </Grid>
                       
                       
                        <Grid item xs={6}>
                          <Typography variant="body1">Principle Employer: {vendor.principleEmployer}</Typography>
                          <Typography variant="body1">State: {vendor.stateId}</Typography>
                          <Typography variant="body1">Place: {vendor.placeName}</Typography>
                          <Typography variant="body1">Contractor: {vendor.clientName}</Typography>
                          <Typography variant="body1">Contact Person: {vendor.clientContactPersonName}</Typography>
                          <Typography variant="body1">Phone: {vendor.clientContactPersonPhoneNumber}</Typography>
                          <Typography variant="body1">Email: {vendor.clientContactPersonEmailId}</Typography>

                          </Grid>
                         
                       
                      </>
                      )}
                   
                 
              
               
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography className="text-center" variant="body1">No vendors available!!<Link to="/createVendor"> Click here to create</Link></Typography>
      )}
    </div>
  );
};
