import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link ,useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import grclogo from '../Images/GRC-Pulse-Logo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {COLORS} from '../Component/colors';
import { SideBar } from './SideBar';


const pages = ['Client Dashboard','Yearly MasterData'];
const settings = [ 'Profile','Log Out'];
export const ClientAppBar = () => {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate=useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
 
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    navigate("/");
    // onLogout();
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    console.log("log out clicked");
  };

  const handleProfile = () => {
    navigate("/client/profile");
  }

  return (
    <AppBar position="static" sx={{backgroundColor:'#FFBF00'}}>
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
          }}
        >
          <img
            src={grclogo}
            width="130px"
            alt="Company Logo"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingRight: "20px",
            }}
          ></img>
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            style={{color:COLORS.dark}}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
             <Link className="nav-link" to={`/${page.replace(/\s+/g,'').toLowerCase()}`}>
                  <Typography sx={{color: "#474747", fontSize:14}}>{page}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
       
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img
            src={grclogo}
            alt="Company Logo"
            width="100px"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          ></img>
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page}
              // onClick={() => handleButtonClick(page)}
             
              sx={{ my: 2, color: '#474747', display: 'block' }}
            >
              <Link className="nav-link" to={`/${page.replace(/\s+/g,'').toLowerCase()}`}>
             <Typography sx={{color: "#474747", fontSize:14}}>{page}</Typography>
              </Link>
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircle sx={{fontSize:'40px',color:COLORS.dark}} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              // <MenuItem key={setting} onClick={setting === 'Log Out' || 'Profile' ? handleLogout  || handleProfile : handleCloseUserMenu}>
              <MenuItem 
                                     key={setting} 
                  onClick={() => {
                    if (setting === 'Log Out' || setting === 'Profile') {
                      setting === 'Log Out' ? handleLogout() : handleProfile();
                    } else {
                      handleCloseUserMenu();
                    }
                  }}
                >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
  )
}
