import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminAppBar from '../Component/AdminAppBar';
import AssessorAppBar from '../Component/AssessorAppBar';
import VendorAppBar from '../Component/VendorAppBar';
import { ClientAppBar } from '../Component/ClientAppBar';

const AppBarSelector = ({ children }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));
  const role = user ? user.type : 'client'; 
  const showAppBar = location.pathname !== '/';
  let AppBarComponent;

  switch (role) {
    case 'admin':
      AppBarComponent = AdminAppBar;
      break;
    case 'assessor':
      AppBarComponent = AssessorAppBar;
      break;
    case 'vendor':
      AppBarComponent = VendorAppBar;
      break;
    case 'client':
    default:
      AppBarComponent = ClientAppBar;
      break;
  }

  return (
    <div>
    {showAppBar && <AppBarComponent />}
      <div>{children}</div>
    </div>
  );
};

export default AppBarSelector;
