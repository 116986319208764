import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
  TextField,
  InputLabel, FormControl
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const statesInIndia = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland', 'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'];
export const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [expandedClient, setExpandedClient] = useState(null);
  const [editingClient, setEditingClient] = useState(null);
  const [clientFormData, setClientFormData] = useState({
    companyId: "",
    principleEmployer: "",
    stateId: "",
    placeName: "",
    companyAddress: "",
    authorisedPersonName:"",
    companyPhoneNumber:"",
    companyEmailId:"",
    clientName: "",
    clientContactPersonName: "",
    clientContactPersonPhoneNumber: "",
    clientContactPersonEmailId: "",
    startDate: "",
    endDate: "",
  });

  const fetchClients = async () => {
    try {
      const response = await api.admin.getClients();
      setClients(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleExpandClick = (companyId) => {
    setExpandedClient(expandedClient === companyId ? null : companyId);
  };
  

  const handleEditClick = (client) => {
    setEditingClient(client.companyId);
    setClientFormData({ ...client });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({ ...clientFormData, [name]: value });
  };

  const handleSave = async () => {
    try {
    const response =  await api.admin.editClient(clientFormData.companyId, clientFormData);
      if(response.status === 200){
        window.alert("updated successfully!!!!");
        setEditingClient(null);
        fetchClients();
      }
      else{
        window.alert("error in submitting");
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCancel = () => {
    setEditingClient(null);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="m-5" >
      {clients.length > 0 ? (
        clients.map((client) => (
          <Accordion key={client.companyId} expanded={expandedClient === client.companyId}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ backgroundColor: 'black', color: 'white' ,borderRadius:'3px'}} />}
              onClick={() => handleExpandClick(client.companyId)}
              aria-controls={`panel-${client.companyId}-content`}
              value="view"
              id={`panel-${client.companyId}-header`}
             
            >
              <Typography variant="h6">{client.principleEmployer}</Typography>
              <Button onClick={() => handleEditClick(client)} variant="contained" style={{position:'absolute',right:'60px'}}>
                Edit
              </Button>
              {/* <Button onClick={() => handleEditClick(client)}  variant="contained" color="error" style={{position:'absolute',right:'50px'}}>
                Delete
              </Button> */}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {editingClient === client.companyId ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Principle Employer"
                        name="principleEmployer"
                        value={clientFormData.principleEmployer}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      
     
      <Select
        labelId="state-id-label"
        value={clientFormData.stateId}
        onChange={handleFormChange}
        name="stateId"
        style={{ width: '100%' }}
      >
      
        <MenuItem value={clientFormData.stateId} >
          {clientFormData.stateId }
        </MenuItem>
        
        {statesInIndia.map((state, index) => (
          <MenuItem key={index} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
  
                       <TextField
                        fullWidth
                        label="Company Address"
                        name="companyAddress"
                        value={clientFormData.companyAddress}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                       <TextField
                        fullWidth
                        label="Place"
                        name="placeName"
                        value={clientFormData.placeName}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Authorised Person Name"
                        name="authorisedPersonName"
                        value={clientFormData.authorisedPersonName}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Email Id"
                        name="companyEmailId"
                        value={clientFormData.companyEmailId}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label=" Phone Number"
                        name="companyPhoneNumber"
                        value={clientFormData.companyPhoneNumber}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                    </Grid>
                   
                   

                    
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Contractor Name"
                        name="clientName"
                        value={clientFormData.clientName}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="State Name"
                        name="stateId"
                        value={clientFormData.stateId}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                       <TextField
                        fullWidth
                        label="Contact Person"
                        name="clientContactPersonName"
                        value={clientFormData.clientContactPersonName}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        name="clientContactPersonEmailId"
                        value={clientFormData.clientContactPersonEmailId}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                       <TextField
                        fullWidth
                        label="Phone"
                        name="clientContactPersonPhoneNumber"
                        value={clientFormData.clientContactPersonPhoneNumber}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                       <TextField
                        fullWidth
                        type="date"
                        label="Contract Start Date"
                        name="startDate"
                        value={new Date(clientFormData.startDate).toISOString().split('T')[0]}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                       <TextField
                        fullWidth
                        type="date"
                        label="Contract End Date"
                        name="endDate"
                        value={new Date(clientFormData.endDate).toISOString().split('T')[0]}
                        onChange={handleFormChange}
                        margin="normal"
                      />
                    </Grid>
                   
                   
                    <Grid item xs={12} md={6}>
                          
                          <Button onClick={handleCancel} variant="contained" color="error" >
                            Cancel
                          </Button>
                          <Button onClick={handleSave} variant='contained' color="success" style={{position:'absolute',right:'20px'}}>
                            Save
                          </Button>
                          </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1">Principle Employer: {client.principleEmployer}</Typography>
                      <Typography variant="body1">Address: {client.companyAddress}</Typography>
                      <Typography variant="body1">State: {client.stateId}</Typography>
                      <Typography variant="body1">Place: {client.placeName}</Typography>
                      <Typography variant="body1">Authorised Person Name: {client.authorisedPersonName}</Typography>
                      <Typography variant="body1">Email Id: {client.companyEmailId}</Typography>
                      <Typography variant="body1">Phone Number: {client.companyPhoneNumber}</Typography>
                    
                   </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Contractor: {client.clientName}</Typography>
                      <Typography variant="body1">Contact Person: {client.clientContactPersonName}</Typography>
                      <Typography variant="body1">Phone: {client.clientContactPersonPhoneNumber}</Typography>
                      <Typography variant="body1">Email: {client.clientContactPersonEmailId}</Typography>
                      <Typography variant="body1">Contract Start Date:{client.startDate}</Typography>
                      <Typography variant="body1">Contract End Date:{client.endDate}</Typography>
                    </Grid>
                   
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography className="text-center" variant="body1">
          No clients available!! <Link to="/createVendor">Click here to create</Link>
        </Typography>
      )}
    </div>
  );
};
