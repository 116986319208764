import React, { useState, useEffect } from "react";
import {Container, Row, Col,Form,Button, ButtonGroup,} from "react-bootstrap";
import reg2 from "../Images/istock.jpg";
import grclogo from "../Images/GRC-Pulse-Logo.png";
import reg from "../Images/home1.jpg";
import reg3 from "../Images/home3.jpg";
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../Services/api";
import AssessorAppBar from "../Component/AssessorAppBar";
 
 
 
const Login = ({setIsLoggedIn}) => {
  const [userType, setUserType] = useState("client");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const img = [reg2, reg];
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();
 
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  const handleUserTypeChange = (type) => {
    setUserType(type);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % img.length);
    }, 4000);
 
    return () => clearInterval(intervalId);
  }, []);
 
  const clientvalidationSchema = yup.object({
    clientUsername: yup.string().required("Username is required"),
    clientPassword: yup.string().required("Password is required"),
  });
  const adminvalidationSchema = yup.object({
    adminPhone: yup.string().required("Username is required"),
    adminPassword: yup.string().required("Password is required"),
  });
  const vendorvalidationSchema = yup.object({
    vendorUsername: yup.string().required("Username is required"),
    vendorPassword: yup.string().required("Password is required"),
  })
  const assessorvalidationSchema = yup.object({
    assessorUsername: yup.string().required("Username is required"),
    assessorPassword: yup.string().required("Password is required"),
  })
 
  const initialValues = {
    clientUsername: "",
    clientPassword: "",
  };
  const initialVendorValues = {
    vendorUsername: "",
    vendorPassword: "",
  };
  const initialAdminValues = {
    adminPhone: "",
    adminPassword: "",
  };
 
const initialAssessorValues = {
  assessorUsername: "",
  assessorPassword: "",
}

const formik = useFormik({
  initialValues: userType === "client" ? initialValues : userType === "vendor" ? initialVendorValues : userType === "assessor"  ? initialAssessorValues  : initialAdminValues,
  validationSchema: userType === "client" ? clientvalidationSchema : userType === "vendor" ? vendorvalidationSchema : userType === "assessor" ? assessorvalidationSchema : adminvalidationSchema,
    onSubmit: async (values) => {
        // console.log("form values", values);
      try {
        let result;
        if (userType === "client") {
          result = await api.client.login({
            clientUsername: values.clientUsername,
            clientPassword: values.clientPassword,
          });
        }
        else if(userType === 'admin'){
          result = await api.admin.login({
            adminPhone: values.adminPhone,
            adminPassword: values.adminPassword,
          });
        }
        else if(userType === 'assessor' ) {
          result = await api.assessor.login({
            assessorUsername: values.assessorUsername,
            assessorPassword: values.assessorPassword,
 
          });
        }
      
        else {
          result = await api.vendor.login({
            vendorUsername: values.vendorUsername,
            vendorPassword: values.vendorPassword,
          });
        // console.log("entered else", result);
        }
        // console.log("result", result?.data);
 
        let temp = result?.data?.filtterData;
        let temp1 = result?.data;
        
        let user ={
          id : temp?.id,
          assessorName: temp?.name,
          companyName : temp?.name,
          companyId : temp?.companyId || temp?.id,
          licenseMode : temp?.licenseMode,
          type : temp?.type
        }
        
        localStorage.setItem(  "user",  JSON.stringify(user)  );

        //  localStorage.setItem("Token",JSON.stringify(temp?.token))
         localStorage.setItem("Token",temp?.token);
 
        if (userType == 'client' && temp1.message === "Login Successfull") {
          window.alert("login sucessfull");
          
          navigate("/clientdashboard");
          setIsLoggedIn(true)
          console.log("inside if", temp1.message);
        }
        else if(userType == 'admin' && temp1.message == 'Login successful....!!!!!!!!'){
          window.alert("admin login successfull");
          navigate("/admindashboard");
        }
         else if(userType == 'vendor' && temp1.message == 'Login Successfull'){
          window.alert("vendor login successfull");
          navigate("/vendordashboard");
        }
        else if(userType == 'assessor'  && temp1.message == 'Login successful....!!!!!!!!'){
          window.alert("assessor login succesfull");
           navigate("/assessordashboard");
           setIsLoggedIn(true);

        }
       
        else{
          window.alert('login unsuccessfull, try with correct username and password');
        }
      } catch (error) {
        window.alert("Please fill in the correct credentials!!");
      }
    },
  });
 
 
  return (
    <>

    <Container>

      <Row className="d-flex justify-content-center align-items-center" >
        <Col className="d-flex"  xs={12} md={8} >
       
        <section>
          <div style={{ textAlign: "center", paddingBottom: "10px" }}>
            <img src={grclogo} alt="logo" width="20%" height="auto"></img>
          </div>
          <form  onSubmit={formik.handleSubmit} method="post">
            <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
              <TextField
                id="outlined-textarea"
                // label={userType === "client" ? "clientUsername" : "vendorUsername"}
                label={"Username"}
                // placeholder="Enter user name*"
                style={{ width: "70%" }}
                name={userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === 'assessor' ? "assessorUsername" :  "vendorUsername"}
                value={userType === "client" ? formik.values.clientUsername : userType === "admin" ? formik.values.adminPhone : userType === "assessor" ? formik.values.assessorUsername : formik.values.vendorUsername}
                onChange={formik.handleChange}
                error={ userType === "client" ? formik.touched.clientUsername && Boolean(formik.errors.clientUsername) :
                  userType === "admin" ? formik.touched.adminPhone && Boolean(formik.errors.adminPhone) : userType === 'assessor' ? formik.touched.assessorUsername && Boolean(formik.errors.assessorUsername) : 
                  formik.touched.vendorUsername && Boolean(formik.errors.vendorUsername)
           }
           helperText={ userType === "client" ? formik.touched.clientUsername && formik.errors.clientUsername : userType === "admin" ? formik.touched.adminPhone && formik.errors.adminPhone : userType === "assessor" ? formik.touched.assessorUsername && formik.errors.assessorUsername  :
                      formik.touched.vendorUsername && formik.errors.vendorUsername
           }
                autoComplete="new-username"
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <FormControl variant="outlined" style={{ width: "70%" }}>
                {/* <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel> */}
                <TextField
                  id="outlined-adornment-password"
                  // placeholder="Enter password*"
                  type={showPassword ? "text" : "password"}
                      InputProps={{
                  endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"

                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
 
                      }}
                  label="Password"
                  name={userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" :  "vendorPassword"}
                  value={userType === "client" ? formik.values.clientPassword : userType === "admin" ? formik.values.adminPassword : userType === "assessor" ? formik.values.assessorPassword : formik.values.vendorPassword}
                  onChange={formik.handleChange}
                  error={ userType === "client" ? formik.touched.clientPassword && Boolean(formik.errors.clientPassword) :
                         userType === "admin" ? formik.touched.adminPassword && Boolean(formik.errors.adminPassword) : userType === "assessor"  ? formik.touched.assessorPassword && Boolean(formik.errors.assessorPassword) : 
                         formik.touched.vendorPassword && Boolean(formik.errors.vendorPassword)
                  }
                  helperText={ userType === "client" ? formik.touched.clientPassword && formik.errors.clientPassword :
                    userType === "admin" ? formik.touched.adminPassword && formik.errors.adminPassword : userType === "assessor" ? formik.touched.assessorPassword && formik.errors.assessorPassword :  
                    formik.touched.vendorPassword && formik.errors.vendorPassword
         }
         autoComplete="new-password"
                />
              </FormControl>
            </div>
 
            <Form.Group
              controlId="formBasicCheckbox"
              className="d-flex justify-content-around mt-2 mb-3"
            >
              {/* <Form.Check
                type="checkbox"
                label="Remember Me"
                style={{ fontSize: "14px" }}
              />
              <Link className="nav-link" to='/forgotpassword' style={{ fontSize: "14px" }}>
                Forgot Password?
              </Link> */}
            </Form.Group>
 
            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-warning"
                type="submit"
                style={{ height: "40px", width: "70%" }}
                disabled={
                  (userType === "client" &&(!formik.values.clientUsername || !formik.values.clientPassword )) ||
                (userType === "vendor" && (!formik.values.vendorUsername || !formik.values.vendorPassword )) ||
              (userType === "admin" && (!formik.values.adminPhone || !formik.values.adminPassword)) || 
             
                (userType === "assessor"  && (!formik.values.assessorUsername || !formik.values.assessorPassword))
              }
              >
                Login
              </button>
            </div>
          </form>
          </section>
          <div className='vr d-none d-md-block' style={{position:'relative',height:'320px'}}>
      <h6 className="p-3 text-white text-center" style={{position:'absolute',backgroundColor:'black', top:'120px',right:'-35px',borderRadius:'50%'}}>Login As</h6>
      </div>  
        </Col>
        <Col xs={12} md={3}>
     
      <section className="p-5">
      <ButtonGroup
          toggle
          className="mb-2 d-grid"
         
        >
  
<Button className="text-center p-2 " onClick={() => handleUserTypeChange("client")} variant={userType === "client" ? "warning" : "white"}>
 <img src={clientIcon} alt="clientIcon" height="40px" width="40px"></img>
<p className='text-center '>Client</p>

</Button>
<Button className='  text-center p-2 ' onClick={() => handleUserTypeChange("vendor")} variant={userType === "vendor" ? "warning" : "white"} >  
<img  src={vendorIcon} alt="vendorIcon" height="40px" width="60px" ></img>
<p className='text-center '>Vendor</p>

</Button>
<Button className='  text-center p-2 ' onClick={() => handleUserTypeChange("assessor")} variant={userType === "assessor"  ? "warning" : "white"} >  
<img  src={assesorIcon} alt="assesorIcon" height="40px" width="60px" ></img>
 <p className='text-center '>Assessor</p> 

</Button>
<Button className=" text-center p-2"  onClick={() => handleUserTypeChange("admin")}  variant={userType === "admin" ? "warning" : "white"}  >
 <img src={adminIcon} alt="adminIcon" height="40px" width="55px"></img>
<p className='text-center '>Admin</p>

</Button>
</ButtonGroup>
  </section>
 
    </Col>
     
     
      </Row>
    </Container>
    </>
  );
};
 
export default Login;
 
