import React,{useEffect,useState} from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider } from '@mui/material';
import api from '../Services/api';
import DateRange from '@mui/icons-material/DateRange';  
import {Col} from "react-bootstrap";
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';

export const AssessorProfile = () => {
  let data = localStorage.getItem('user');
  let assessorDataId = JSON.parse(data);
  let id = assessorDataId.id;
  console.log('assessor Id',id);
    const [editMode, setEditMode] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [originalData, setOriginalData] = useState({});
    
    const [assessorData, setAssessorData] = useState({   
        
        assessorCompanyName: "",
        companyId:"",
        assessorAddress: "",
        assessorName: "",
        assessorEmail: "",
        assessorPhoneNumber: "",
        joiningDate: " ",
        exitDate: " ",

       
     
});

const[companyData , setCompanyData] = useState({
    principleEmployer: "",
            stateId: "",
            placeName: "",
            authorisedPersonName: "",
            companyEmailId: "",
            companyPhoneNumber: "",
            companyAddress: "",
            authorisedPersonName : "",
            companyEmailId: "",
            companyPhoneNumber:"",
            clientName: "",
            clientContactPersonName: "",
            clientContactPersonPhoneNumber: "",
            clientContactPersonEmailId: "",
            
    
})



  const handleEdit = () => {
    setOriginalData({...assessorData});
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setAssessorData({...originalData});
    
   
  };

  
 
  const handleSave = ()=>{
   setEditMode(false);
   api.assessor.editAssessor(id,assessorData).then((response) => {
    if(response.status===200){
    window.alert("Edited Successfully");
    setOriginalData({ ...companyData });
    }
    else{
      window.alert("error in editing the details");
    }
   })
   .catch(error =>{
    window.alert("Error occurred while updating")
  });
  };

  

  useEffect(() => {
   
    api.assessor.findOne(id)
    .then(response => {
      const temp = response.data.data;
    
    console.log("assessorData",temp);
    const temp1 = response.data.data.companyId;
    console.log("companyid",temp1);
       setAssessorData(temp);
       setOriginalData(temp);
       setCompanyId(temp1); 
    
    })
    .catch(error => console.error('error fetching company data', error));
  },[]);

useEffect(() => {
    let id = companyId;
    console.log("companyid",id);
api.vendor.findOne(id).then(response =>{
    const temp2 = response.data;
    console.log("client details",temp2);
    setCompanyData(temp2);
})
},[companyId]);


 
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setAssessorData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

  return (
    <Card className="d-md-flex m-5">
  
  <Col className=" p-4">
    
    <div>
    <div className='d-flex'>
   <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
   <Typography className="p-2" variant="h6" gutterBottom>
 Principle Employer
</Typography>
   </div>


 <Typography className="mt-2 " variant="body2"  component="div">
 <FaBuilding /> principle Employer Name: {companyData?.data?.principleEmployer} 
 </Typography>
 <Typography  className="mt-2 " variant="body2"  component="div">
 <FaMapMarkerAlt /> Address {companyData?.data?.companyAddress} 
 </Typography>
 <Typography  className="mt-2" variant="body2"  component="div">
 <FaMapMarkerAlt /> State: {companyData?.data?.stateId} 
 </Typography>
 <Typography  className="mt-2" variant="body2"  component="div">
 <FaMapMarkerAlt /> place Name: {companyData?.data?.placeName} 
 </Typography>




 </div>
<br></br>
 <div>
<Typography  variant="h6" gutterBottom>
Contact Person Details
</Typography>

<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt /> Name: {companyData?.data?.authorisedPersonName}
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaEnvelope />  Email: {companyData?.data?.companyEmailId} 
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaPhone />  PhoneNumber: {companyData?.data?.companyPhoneNumber} 
</Typography>
</div>

</Col>
<div className='vr'></div>
      <Col className=" p-4">
    
           <div>
           <div className='d-flex'>
          <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Client
    </Typography>
          </div>
     
     
        <Typography className="mt-2 " variant="body2"  component="div">
        <FaBuilding /> client Name: {companyData?.data?.clientName} 
        </Typography>
        <Typography  className="mt-2 " variant="body2"  component="div">
        <FaMapMarkerAlt /> Address: {companyData?.data?.companyAddress} 
        </Typography>
        <Typography  className="mt-2" variant="body2"  component="div">
        <FaMapMarkerAlt /> State: {companyData?.data?.stateId} 
        </Typography>
        <Typography  className="mt-2" variant="body2"  component="div">
 <FaMapMarkerAlt /> place Name: {companyData?.data?.placeName} 
 </Typography>
       
       

       
        </div>
       <br></br>
        <div>
<Typography  variant="h6" gutterBottom>
    Contact Person Details
</Typography>

<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt /> Name: {companyData?.data?.clientContactPersonName}
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaEnvelope />  Email: {companyData?.data?.clientContactPersonEmailId} 
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaPhone />  PhoneNumber: {companyData?.data?.clientContactPersonPhoneNumber} 
</Typography>
</div>

      </Col>
      <div className='vr'></div>
 
      <Col className=" p-4 bg-dark">
        <div className='d-flex' >
          <img   className='bg-light' src={assesorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2 text-white" variant="h6" gutterBottom>
        Assessor 
  </Typography>
  </div>
        <div>
    
     <Typography className="mt-2 text-white" variant="body2"  component="div">
     <FaBuilding /> Company Name: {assessorData?.assessorCompanyName}
     </Typography>
     <Typography className="mt-2 text-white" variant="body2" component="div">
<FaUserAlt />  Address: {assessorData?.assessorAddress}
</Typography>
<Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> JoiningDate : {assessorData?.joiningDate}
            </Typography> 
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> ExitDate : {assessorData?.exitDate}
            </Typography> 
     </div>
   
     <div className='mt-3 pt-4 bg-warning'>
          
          <div  className='text-center'>
            <Typography variant="body1" className="mt-3 text-white " component="div" >
            <FaUserAlt /> {' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="assessorName"
                  value={assessorData?.assessorName}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}
                  
                 
                />
              ) : (
                assessorData?.assessorName
              )}
            </Typography>
            <Typography variant="body1"  className="mt-3 text-white" component="div" >
              <FaPhone />{' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="assessorPhoneNumber"
                  value={assessorData?.assessorPhoneNumber}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}
                
                  
                />
              ) : (
                assessorData?.assessorPhoneNumber
              )}
            </Typography>
            <Typography variant="body1"  className="mt-3 text-white" component="div" >
              <FaEnvelope />{' '}
              {editMode ? (
                <TextField
                  type="text"
                  name="assessorEmail"
                  value={assessorData?.assessorEmail}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { color: '#000000' } }}
                  style={{backgroundColor:"white"}}                   
                 
                  
                />
              ) : (
                assessorData?.assessorEmail
              )}
            </Typography>
          </div>
          {/* Edit and Save Buttons */}
          {editMode ? (
            <div className="mt-3 pb-3 text-center" >
                <button onClick={handleCancelEdit} className="mx-3 btn btn-sm btn-danger" >
                Cancel
              </button>
              <button onClick={handleSave} className='mx-2 btn btn-sm btn-success'  >
                Save
              </button>
            
            </div>
          ) : (
            <div className='text-center'>
            <button onClick={handleEdit} className='mt-3 btn btn-sm btn-warning text-white' style={{position:'relative',top:'-170px',border:'1px solid'}} >
              Edit
            </button>
            </div>
          )}
    </div>

   </Col>
     



    </Card>
  )
}
