export const COLORS = {
    primary: '#007bff',
    secondary: '#848383',
    success: '#50c878',
    danger: '#FAA0A0',
    warning: '#fff44f',
    info: '#17a2b8',
    light: '#eeee',
    dark: 'black',
    bggray:'#d5d2d2',
    red:'#D22B2B'
  };

  