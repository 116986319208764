import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
  TextField,
  InputLabel, FormControl
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const statesInIndia = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland', 'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'];

export const AssessorList = () => {
    const [assessors, setAssessors] = useState([]);
    const [expandedAssessor, setExpandedAssessor] = useState(null);
    const [editingAssessor, setEditingAssessor] = useState(null);
    const [assessorFormData, setAssessorFormData] = useState({
        assessorId: '',
        companyId: '',
        principleEmployer: '',
        stateId: '',
        placeName:'',
        companyAddress: '',
        authorisedPersonName: '',
        companyEmailId: '',
        companyPhoneNumber: '',
        clientName: '',
        clientContactPersonName: '',
        clientContactPersonPhoneNumber: '',
        clientContactPersonEmailId: '',
        assessorCompanyName: '',
        assessorAddress: '',
        assessorName: '',
        assessorEmail: '',
        assessorPhoneNumber: '',
        joiningDate: '',
        exitDate: '',
    });
    const [companyOptions,setCompanyOptions]=useState([]);

    const fetchClients = async ()=>{
        try{
            const response = await api.admin.getClients();
            setCompanyOptions(response.data);
            console.log(response.data);
        }
        catch(error){
            console.error('Error fetching clients:', error); 
        }
    };
  
    const fetchAssessors = async () => {
      try {
        const response = await api.admin.getAssessors();
        setAssessors(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
  
  
    const handleExpandClick = (assessorId) => {
      setExpandedAssessor(expandedAssessor=== assessorId ? null : assessorId);
    };
    
  
    const handleEditClick = (assesor) => {
      setEditingAssessor(assesor.assessorId);
      setAssessorFormData({ ...assesor });
    };
  
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setAssessorFormData({ ...assessorFormData, [name]: value });
  
      
        if (name === 'principleEmployer') {
            axios.get(`http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames`)
         .then(response => {
            const selectedCompany = response.data.find(company => company.principleEmployer === value);
          if (selectedCompany) {
            setAssessorFormData(prevData => ({
              ...prevData,
              principleEmployer:value,
              companyId:selectedCompany.companyId,
              stateId: selectedCompany.stateId,
              companyAddress: selectedCompany.companyAddress,
              authorisedPersonName: selectedCompany.authorisedPersonName,
              companyEmailId: selectedCompany.companyEmailId,
              companyPhoneNumber: selectedCompany.companyPhoneNumber,
              clientName: selectedCompany.clientName,
              clientContactPersonName: selectedCompany.clientContactPersonName,
              clientContactPersonPhoneNumber: selectedCompany.clientContactPersonPhoneNumber,
              clientContactPersonEmailId: selectedCompany.clientContactPersonEmailId,
            }));
          }
        })
    }
      };
  
    const handleSave = async () => {
      try {
      const response =  await api.admin.editAssessor(assessorFormData.assessorId, assessorFormData);
        if(response.status === 200){
          window.alert("updated successfully!!!!");
          setEditingAssessor(null);
          fetchAssessors();
        }
        else{
          window.alert("error in submitting");
        }
      } catch (error) {
        console.error('Error saving data:', error);
      }
    };
  
    const handleCancel = () => {
      setEditingAssessor(null);
    };
  
    useEffect(() => {
      fetchAssessors();
      fetchClients();
      
    }, []);
  
    return (
      <div className="m-5" >
        {assessors.length > 0 ? (
          assessors.map((assessor) => (
            <Accordion key={assessor.assessorId} expanded={expandedAssessor === assessor.assessorId}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ backgroundColor: 'black', color: 'white' ,borderRadius:'3px'}} />}
                onClick={() => handleExpandClick(assessor.assessorId)}
                aria-controls={`panel-${assessor.assessorId}-content`}
                value="view"
                id={`panel-${assessor.assessorId}-header`}
               
              >
                <Typography variant="h6">{assessor.assessorCompanyName}</Typography>
                <Button onClick={() => handleEditClick(assessor)} variant="contained" style={{position:'absolute',right:'60px'}}>
                  Edit
                </Button>
                {/* <Button onClick={() => handleEditClick(assessor)}  variant="contained" color="error" style={{position:'absolute',right:'50px'}}>
                  Delete
                </Button> */}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {editingAssessor=== assessor.assessorId ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label=" Company Name"
                          name="assessorCompanyName"
                          value={assessorFormData.assessorCompanyName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        
       
    
                         <TextField
                          fullWidth
                          label="Company Address"
                          name="assessorAddress"
                          value={assessorFormData.assessorAddress}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        
                        <TextField
                          fullWidth
                          label="Person Name"
                          name="assessorName"
                          value={assessorFormData.assessorName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Email Id"
                          name="assessorEmail"
                          value={assessorFormData.assessorEmail}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label=" Phone Number"
                          name="assessorPhoneNumber"
                          value={assessorFormData.assessorPhoneNumber}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          type="date"
                          label="Joining Date"
                          name="joiningDate"
                          value={new Date(assessorFormData.joiningDate).toISOString().split('T')[0]}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          type="date"
                          label=" Exit Date"
                          name="exitDate"
                          value={new Date(assessorFormData.exitDate).toISOString().split('T')[0]}
                          onChange={handleFormChange}
                          margin="normal"
                        />

                       <TextField
                          fullWidth
                          label="Contractor Name"
                          name="clientName"
                          value={assessorFormData.clientName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          label="Contact Person Name"
                          name="clientContactPersonName"
                          value={assessorFormData.clientContactPersonName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          label="Email"
                          name="clientContactPersonEmailId"
                          value={assessorFormData.clientContactPersonEmailId}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          label="Phone"
                          name="clientContactPersonPhoneNumber"
                          value={assessorFormData.clientContactPersonPhoneNumber}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                      </Grid>
                     
                     
  
                     
                      <Grid item xs={12} md={6}>
                      <Select
                            name="principleEmployer"
                            value={assessorFormData.principleEmployer}
                            onChange={handleFormChange}
                            style={{ width: '100%' }}
                            >
                                 <MenuItem value={assessorFormData.principleEmployer} >
          {assessorFormData.principleEmployer }
        </MenuItem>
                            
                            {Array.isArray(companyOptions) && companyOptions.map((company) => (
    <MenuItem key={company.companyId} value={company.principleEmployer}>
      {company.principleEmployer} {company.stateId}
    </MenuItem>
  ))}
                          </Select>
                        <TextField
                          fullWidth
                          label="State"
                          name="stateId"
                          value={assessorFormData.stateId}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        
                        <TextField
                          fullWidth
                          label="place"
                          name="placeName"
                          value={assessorFormData.placeName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          label="Address"
                          name="companyAddress"
                          value={assessorFormData.companyAddress}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                         <TextField
                          fullWidth
                          label="Authorised Person Name"
                          name="authorisedPersonName"
                          value={assessorFormData.authorisedPersonName}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Email"
                          name="companyEmailId"
                          value={assessorFormData.companyEmailId}
                          onChange={handleFormChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Phone"
                          name="authorisedPersonName"
                          value={assessorFormData.companyPhoneNumber}
                          onChange={handleFormChange}
                          margin="normal"
                        />

                       
                        
                      </Grid> 
                     
                     
                      <Grid item xs={12} md={6}>
                            
                            <Button onClick={handleCancel} variant="contained" color="error" >
                              Cancel
                            </Button>
                            <Button onClick={handleSave} variant='contained' color="success" style={{position:'absolute',right:'20px'}}>
                              Save
                            </Button>
                            </Grid>
                    </>
                  ) : (
                    <>
                     
                      <Grid item xs={6}>
                        <Typography variant="body1">Company Name: {assessor.assessorCompanyName}</Typography>
                        <Typography variant="body1">Address: {assessor.assessorAddress}</Typography>
                        <Typography variant="body1">Name: {assessor.assessorName}</Typography>
                        <Typography variant="body1">Phone: {assessor.assessorPhoneNumber}</Typography>
                        <Typography variant="body1">Email: {assessor.assessorEmail}</Typography>
                        <Typography variant="body1">Joining Date:{assessor.joiningDate}</Typography>
                        <Typography variant="body1">Exit Date:{assessor.exitDate}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">Prinicple Employer Name: {assessor.principleEmployer}</Typography>
                        <Typography variant="body1">State: {assessor.stateId}</Typography>
                        <Typography variant="body1">Place Name: {assessor.placeName}</Typography>
                        <Typography variant="body1">Address: {assessor.companyAddress}</Typography>
                        <Typography variant="body1">authorisedPersonName: {assessor.authorisedPersonName}</Typography>
                        <Typography variant="body1">Email: {assessor.companyEmailId}</Typography>
                        <Typography variant="body1">Phone: {assessor.companyPhoneNumber}</Typography>
                        
                        <Typography variant="body1">Contractor Name:{assessor.clientName}</Typography>
                        <Typography variant="body1">Contact Person Name:{assessor.clientContactPersonName}</Typography>
                        <Typography variant="body1">Email:{assessor.clientContactPersonEmailId}</Typography>
                        <Typography variant="body1">Phone:{assessor.clientContactPersonPhoneNumber}</Typography>
                       
                      </Grid>
                     
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography className="text-center" variant="body1">
            No assessor available!! <Link to="/createAssessor">Click here to create</Link>
          </Typography>
        )}
      </div>
  )
}
