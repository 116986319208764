import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CorporateFare as CorporateFareIcon, CardMembership as CardMembershipIcon, LocationOn, Email, Phone, AccountCircle, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import axios from 'axios';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';


const useStyles = makeStyles({
    card: {
      margin: 50,
      padding:20
    },
    
    detail: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      marginBottom: 15,
    },
    icon: {
      marginRight: 10,
    },
    textField: {
      width: '100%',
    },
    selectField: {
      width: '100%', 
    },
   
  });
export default function AdminAssessor () {
   
   
  const classes = useStyles();
  const [formData, setFormData] = useState({
    companyId: '',
    stateId: '',
    placeName: '',
    principleEmployer:'',
    clientName:'',
    companyAddress:'',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    assessorCompanyName:'',
    assessorAddress:'',
    assessorName:'',
    assessorPhoneNumber:'',
    assessorEmail:'',
    assessorUsername: '',
    assessorPassword: '',
    joiningDate: '',
    exitDate: '',
  
  });
  const [errors, setErrors] = useState({});
  const [clientOptions, setClientOptions] = useState([]);

  const [showPassword, setShowPassword] = React.useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    axios.get('http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames')
      .then(response => {
        setClientOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Handle changes for companyName dropdown
    if (name === 'clientName') {
      axios.get(`http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames`)
        .then(response => {
          const selectedClient = response.data.find(client => client.clientName === value);
          console.log("get companyname:", selectedClient );
          if (selectedClient) {
            const {  companyAddress ,principleEmployer,companyId, placeName , clientContactPersonName,  clientContactPersonEmailId ,clientContactPersonPhoneNumber , stateId} = selectedClient;
            setFormData(prevState => ({
              ...prevState,
             clientName: value,
              companyId,
              principleEmployer,
              companyAddress,
              placeName, 
              clientContactPersonName,
            clientContactPersonEmailId,
              clientContactPersonPhoneNumber,
              stateId,
        
            }));
            console.log("clientId",selectedClient.companyId)
          }
        })
        .catch(error => {
          console.error('Error fetching vendor details:', error);
        });
    }
  
    if (name !== 'clientName') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    // const numberPattern = /^[0-9]+$/;

    // if (!formData.companyName) newErrors.companyName = 'Select Company';
    if (!formData.clientName) newErrors.clientName= 'Select Client';
    if (!formData.assessorCompanyName) newErrors.assessorCompanyName = 'company Name is required';
    if (!formData.assessorAddress) newErrors.assessorAddress = 'company adrress is required';
    if (!formData.assessorName) newErrors.assessorName = 'spoc Name is required';
    if (!emailPattern.test(formData.assessorEmail)) {
      newErrors.assessorEmail = formData.assessorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.assessorPhoneNumber)) {
      newErrors.assessorPhoneNumber = formData.assessorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
   
    if (!formData.assessorUsername) newErrors.assessorUsername = 'Username is required';
    if (!formData.assessorPassword) newErrors.assessorPassword = 'Password is required';
    if (!formData.joiningDate) newErrors.joiningDate = 'Contract Start Date is required';
    if (!formData.exitDate) newErrors.exitDate = 'Contract End Date is required';

    const selectField =  formData.clientName;
    if (!selectField) {
      newErrors.selectField = 'Please select client';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    
    console.log("formData:",formData);
    if (validateForm()) {
    axios.post('http://35.154.135.215:8080/api/Assessor/create-assessor', formData)
      .then(response => {
        // Assuming the response contains the vendor data you want to store
        if(response.status===200){
        const assessorData = response.data;
         // Store the vendor data in local storage
         localStorage.setItem('assessor', JSON.stringify(assessorData));
        window.alert("created");
        

        }
        
       
      })
      .catch(error => {
        console.error('Error saving vendor profile:', error);
      });
    }
  };
  

  return (
    <div>
  
<Card className={classes.card} >
<Typography className="text-center bg-black text-white p-3 mb-4" variant="h5" >
  Create Assessor
</Typography>
<CardContent>
{/* <Typography className="text-center mb-4 " color="textSecondary" gutterBottom>
- - - - - - - - - <span className="text-dark"> Company Details </span> - - - - - - - - - - -
</Typography> */}
{/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
{/* <div className={classes.detail}>
  <CorporateFareIcon className={classes.icon} />
  <Select
    className={classes.selectField}
    label="Vendor Name"
    value={formData.companyName}
    onChange={handleChange}
    name="companyName"
    displayEmpty
    error={!!errors.companyName}
   
  >
    <MenuItem value="" disabled>Select company</MenuItem>
    {Array.isArray(vendorOptions) && vendorOptions.map((vendor, index) => (
<MenuItem key={index} value={vendor.companyName}>{vendor.companyName + " " + vendor.unit}</MenuItem>
))}

  </Select>
  
  
</div> */}



{/* <div className={classes.detail}>
  <CardMembershipIcon className={classes.icon} />
  <TextField className={classes.textField} label="Company ID" name="companyId" value={formData.companyId} onChange={handleChange} />
</div> */}
  

<div className={classes.detail}>
  <CorporateFareIcon className={classes.icon} />
  <Select
  className={classes.selectField}
  value={formData.clientName} // Ensure default is an empty string
  onChange={handleChange}
  name="clientName"
  displayEmpty
   error={!!errors.clientName}
>
  <MenuItem value="" disabled>Select Client</MenuItem> {/* Use an empty string */}
  {Array.isArray(clientOptions) && clientOptions.map((client, index) => (
    <MenuItem key={index} value={client.clientName}>
      {client.clientName} {client.stateId}
    </MenuItem>
  ))}
</Select>
</div>
{errors.selectField && (
<div className="pb-4 px-5" style={{ color: 'red', fontSize: '14px'}}>
{errors.selectField}
</div>
)}
{/* <div className={classes.detail}>
  <CardMembershipIcon className={classes.icon} />
  <TextField className={classes.textField} label="Client ID" name="clientId" value={formData.clientId} onChange={handleChange} />
</div> */}
<div className={classes.detail}>
  <LocationOn className={classes.icon} />
  <TextField className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} InputProps={{ readOnly: true }}/>
</div>
<div className={classes.detail}>
  <LocationOn className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Place"
    name="placeName"
    value={formData.placeName}
    // onChange={handleChange}
    
    InputProps={{ readOnly: true }}
   
  />
</div>
<div className={classes.detail}>
  <Person className={classes.icon} />
  <TextField className={classes.textField} label="Authorised Person Name" name="clientContactPersonName" value={formData.clientContactPersonName} onChange={handleChange}  InputProps={{ readOnly: true }}
    />
</div>
<div className={classes.detail}>
  <Email className={classes.icon} />
  <TextField className={classes.textField} label="Email" name="clientContactPersonEmailId" value={formData.clientContactPersonEmailId} onChange={handleChange} InputProps={{ readOnly: true }}/>
</div>
<div className={classes.detail}>
  <Phone className={classes.icon} />
  <TextField className={classes.textField} label="Phone Number" name="clientContactPersonPhoneNumber" value={formData.clientContactPersonPhoneNumber} onChange={handleChange} type='number' InputProps={{ readOnly: true }} />
</div>
{/* <div className={classes.detail}>
<LocationOn className={classes.icon} />
  <TextField className={classes.textField} label="State Id" name="stateId" value={formData.stateId} onChange={handleChange} type='number' InputProps={{ readOnly: true }}/>
</div> */}
{/* <div className={classes.detail}>
  <LocationOn className={classes.icon} />
  <Select
    className={classes.selectField}
    label="State ID"
    value={formData.stateId}
    onChange={handleChange}
    name="stateId"
  >
    {statesInIndia.map((state, index) => (
      <MenuItem key={index} value={state}>{state}</MenuItem>
    ))}
  </Select>
</div> */}

{/* <div className={classes.detail}>
  <FormatListNumbered className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Unit"
    type='number'
    placeholder="Enter an integer"
    name="unit"
    value={formData.unit}
    onChange={handleChange}
    InputProps={{ readOnly: true }}
  />
</div> */}
{/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
<Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - <span className="text-dark">Assesor Details</span> - - - - - - - - - - -
</Typography>
<div className={classes.detail}>
  <Person className={classes.icon} />
  <TextField className={classes.textField} label="Assessor Company Name" name="assessorCompanyName" value={formData.assessorCompanyName} onChange={handleChange}  error={!!errors.assessorCompanyName}
    helperText={errors.assessorCompanyName}/>
</div>
<div className={classes.detail}>
<LocationOn className={classes.icon} />
  <TextField className={classes.textField} label="Assessor Company Address" name="assessorAddress" value={formData.assessorAddress} onChange={handleChange}  error={!!errors.assessorAddress}
    helperText={errors.assessorAddress}/>
</div>
<div className={classes.detail}>
  <Person className={classes.icon} />
  <TextField className={classes.textField} label="Contact Person" name="assessorName" value={formData.assessorName} onChange={handleChange}  error={!!errors.assessorName}
    helperText={errors.assessorName}/>
</div>
<div className={classes.detail}>
  <Phone className={classes.icon} />
  <TextField className={classes.textField} label="Contact Person Phone Number" name="assessorPhoneNumber" value={formData.assessorPhoneNumber} onChange={handleChange} error={!!errors.assessorPhoneNumber}
      helperText={errors.assessorPhoneNumber}/>
</div>
<div className={classes.detail}>
  <Email className={classes.icon} />
  <TextField className={classes.textField} label="Contact Person Email" name="assessorEmail" value={formData.assessorEmail} onChange={handleChange} error={!!errors.assessorEmail}
    helperText={errors.assessorEmail}/>
</div>
{/* <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Contract Details </span> - - - - - - - - - - -
</Typography> */}
<div className={classes.detail}>
  <DateRange className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Date of joining"
    type="date"
    name="joiningDate"
    value={formData.joiningDate}
    onChange={handleChange}
    InputLabelProps={{
      shrink: true,
    }}
    error={!!errors.joiningDate}
    helperText={errors.joiningDate}
  />
</div>
<div className={classes.detail}>
  <DateRange className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Date of exit"
    type="date"
    name="exitDate"
    value={formData.exitDate}
    onChange={handleChange}
    InputLabelProps={{
      shrink: true,
    }}
    error={!!errors.exitDate}
    helperText={errors.exitDate}
  />
</div>
<Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Login Details </span> - - - - - - - - - - -
</Typography>

<div className={classes.detail}>
  <AccountCircle className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Username"
    name="assessorUsername"
    value={formData.assessorUsername}
    onChange={handleChange}
    style={{ width: '100%' }}
    error={!!errors.assessorUsername}
    helperText={errors.assessorUsername}
  />
</div>
{/* <div className={classes.detail}>
  <Lock className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Vendor Password"
    type="password"
    name="vendorPassword"
    value={formData.vendorPassword}
    onChange={handleChange}
    error={!!errors.vendorPassword}
    helperText={errors.vendorPassword}
  />
</div> */}
<div className={classes.detail}>
<Lock className={classes.icon} />
<FormControl sx={{ width: '100%' }} variant="outlined">

  <TextField
    id="outlined-adornment-password"
    type={showPassword ? 'text' : 'password'}
    InputProps={{
    endAdornment:(
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  }}
   
    label="Password"
   
    name="assessorPassword"
    value={formData.assessorPassword}
    onChange={handleChange}
    error={!!errors.assessorPassword}
    helperText={errors.assessorPassword}
  />
</FormControl>

</div>
{/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} />

<div className={classes.detail}>
  <DateRange className={classes.icon} />
  <TextField
    className={classes.textField}
    label="Licence End Day"
    type="date"
    name="endDate"
    value={formData.endDate}
    onChange={handleChange}
    InputLabelProps={{
      shrink: true,
    }}
  />
</div>
<Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
{/* <div style={{ display: 'flex', gap: '10px' }}>
<Button variant="contained" color="primary" style={{ backgroundColor: '#dc3545' }} onClick={handleSave}>Save</Button>
<Button variant="contained" color="primary" style={{ backgroundColor: '#dc3545' }} onClick={handleSubmit}>Create Database</Button>
</div>  */}
<button className="btn btn-warning  text-white w-100 mt-4 "   disabled={!validateForm} onClick={handleSave }>Save</button> 

</CardContent>
</Card>
</div>
  )
}
