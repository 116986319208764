import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider } from '@mui/material';
import DateRange from '@mui/icons-material/DateRange';  
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
// import { MdLocationOn, MdBusiness } from 'react-icons/md';
import axios from 'axios';
import api from '../Services/api';
import {Col} from "react-bootstrap";



const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [contactData, setContactData] = useState({
          vendorId : " ",
          assessorId :" ",
          companyId : " ",
          // companyName: " ",
          // companyAddress : " ",
          // stateId: "",
          // placeName: "",
          // contactPersonName : " ",
          vendorCompanyName : " ",
          vendorCompanyAddress: "",
          natureOfWork: "",
          vendorName: " ",
          vendorPhoneNumber:" ",
          vendorEmail: " ",
        
          vendorContractStartDate: " ",
          vendorContractEndDate: " ",

  });

 

  const [assessorData, setAssessorData] = useState({      
          assessorId: "",
          clientId: "",
          assessorCompanyName: "",
          assessorAddress: "",
          assessorName: "",
          assessorEmail: "",
          assessorPhoneNumber: ""
       
  });
  const [companyData, setCompanyData] = useState({
    principleEmployer: '',
    companyAddress: '',
   
    // companyEmailId: '',
    // companyPhoneNumber: '',
    clientName:'',
      clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    natureOfBusiness: '',
    stateId: '',
    placeName: '',
    unit:  '',
  });



  useEffect(() =>{
    api.vendor.findvendor()
    .then(response =>{
      const temp = response.data.filtterData;
      console.log("temp",temp);
      setContactData(temp);
      setOriginalData(temp);
    })
    .catch(error => console.error('error fetching profile data:', error))
  }, []);

  

  useEffect(() => {
    const id = contactData.assessorId;
     console.log("assessorid:",id);
    api.assessor.findOne(id)
    .then(response => {
      const temp1 = response.data;
    console.log("temp1",temp1);
       setAssessorData(temp1)
    
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);


  useEffect(() => {
    const requestData1 = contactData.companyId;
     console.log("id1:",requestData1);
    api.vendor.findOne(requestData1)
    .then(response => {
      // console.log("masterdata:", response);
      const temp2 = response.data;
    console.log("temp2",temp2);
       setCompanyData(temp2)
      //  setOriginalData(temp2)
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);

  const handleEdit = () => {
    setOriginalData({...contactData});
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setContactData({...originalData});
   
  };

  
 
  const handleSave = ()=>{
    setEditMode(false);
    let id = contactData.vendorId;
    api.vendor.updateOne(id, contactData)
    .then((response) => {
      if(response.status===200){
      window.alert("Edited Successfully");
      setOriginalData({ ...contactData });
      }
      else{
        window.alert("error in editing the details");
      }
    })
    .catch(error =>{
      window.alert("Error occurred while updating")
    });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>

 
        <Card className="d-md-flex m-4" >
     
      
          <Col className=" p-4">
          <div className='d-flex'>
          <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Client
    </Typography>
          </div>
         
               <div >
            {/* <Typography variant="h5" gutterBottom >
              Company Details
            </Typography> */}
         
            <Typography className="mt-2 " variant="body2"  component="div">
            <FaBuilding /> Principle Employer : {companyData?.data?.principleEmployer}
            </Typography>
            <Typography className="mt-2 " variant="body2" component="div">
    <FaUserAlt /> Client Name: {companyData?.data?.clientName}
    </Typography>
            <Typography  className="mt-2 " variant="body2"  component="div">
            <FaMapMarkerAlt /> Address: {companyData?.data?.companyAddress}
            </Typography>
             <Typography className="mt-2 " variant="body2"  component="div" >
              <FaMapMarkerAlt /> Location: {companyData?.data?.placeName}
            </Typography> 
            </div>
          
            <div className="pt-3">
    <Typography  variant="h6" gutterBottom>
        Contact Person Details
    </Typography>
   
   
    <Typography className="mt-2" variant="body2" component="div">
    <FaUserAlt /> Name: {companyData?.data?.clientContactPersonName}
    </Typography>
    <Typography className="mt-2 " variant="body2"  component="div" >
    <FaEnvelope />  Email: {companyData?.data?.clientContactPersonEmailId}
    </Typography>
    <Typography className="mt-2 " variant="body2"  component="div" >
    <FaPhone />  PhoneNumber: {companyData?.data?.clientContactPersonPhoneNumber}
    </Typography>
    </div>
   
          </Col>
         
           <div className='vr'></div>
           <Col className=" p-4">
        <div className='d-flex' >
          <img   className='bg-light' src={assesorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Assessor 
  </Typography>
  </div>
        <div>
     {/* <Typography variant="h5" gutterBottom >
       Company Details
     </Typography> */}
  
     <Typography className="mt-2" variant="body2"  component="div">
     <FaBuilding /> Company Name: {assessorData?.data?.assessorCompanyName}
     </Typography>
     <Typography className="mt-2" variant="body2" component="div">
<FaUserAlt />  Address: {assessorData?.data?.assessorAddress}
</Typography>

     </div>
   
     <div className='pt-3'>
<Typography  variant="h6" gutterBottom>
 Contact Person Details
</Typography>


<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt />  Name: {assessorData?.data?.assessorName}
</Typography>
<Typography className="mt-2 " variant="body2"  component="div" >
<FaEnvelope />  Email: {assessorData?.data?.assessorEmail}
</Typography>
<Typography className="mt-2 " variant="body2"  component="div" >
<FaPhone />  PhoneNumber: {assessorData?.data?.assessorPhoneNumber}
</Typography>
</div>

   </Col>
        <div className="vr"></div>
        <Col className="bg-dark">
        
          <CardContent className="card-content  p-4 " >
          <div className='d-flex' >
          <img   className='bg-light' src={vendorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2 text-white" variant="h6" gutterBottom>
        Vendor
  </Typography>
  </div>
          {/* <Typography variant="h5" gutterBottom >
              Vendor Company Details
            </Typography> */}
            <Typography className="mt-2 text-white" variant="body2"  component="div">
            <FaBuilding /> Company Name : {contactData.vendorCompanyName}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaMapMarkerAlt /> Address : {contactData.vendorCompanyAddress}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaMapMarkerAlt /> Nature of work : {contactData.natureOfWork}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange /> ContractStartDate : {contactData.vendorContractEndDate}
            </Typography>
             <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> ContractEndDate : {contactData.vendorContractEndDate}
            </Typography> 

       <div className='mt-3 pt-4 bg-warning'>
          
            <div  className='text-center'>
              <Typography variant="body1" className="mt-3 text-white " component="div" >
              <FaUserAlt /> {' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="vendorName"
                    value={contactData.vendorName}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}
                    
                   
                  />
                ) : (
                  contactData.vendorName
                )}
              </Typography>
              <Typography variant="body1"  className="mt-3 text-white" component="div" >
                <FaPhone />{' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="vendorPhoneNumber"
                    value={contactData.vendorPhoneNumber}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}
                  
                    
                  />
                ) : (
                  contactData.vendorPhoneNumber
                )}
              </Typography>
              <Typography variant="body1"  className="mt-3 text-white" component="div" >
                <FaEnvelope />{' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="vendorEmail"
                    value={contactData.vendorEmail}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}                   
                   
                    
                  />
                ) : (
                  contactData.vendorEmail
                )}
              </Typography>
            </div>
            {/* Edit and Save Buttons */}
            {editMode ? (
              <div className="mt-3 pb-3 text-center" >
                  <button onClick={handleCancelEdit} className="mx-3 btn btn-sm btn-danger" >
                  Cancel
                </button>
                <button onClick={handleSave} className='mx-2 btn btn-sm btn-success'  >
                  Save
                </button>
              
              </div>
            ) : (
              <div className='text-center'>
              <button onClick={handleEdit} className='mt-3 btn btn-sm btn-warning text-white' style={{position:'relative',top:'-170px',border:'1px solid'}} >
                Edit
              </button>
              </div>
            )}
      </div>
          </CardContent>
          </Col>
         
 


        </Card>
      




    </>
  );
};

export default Profile;